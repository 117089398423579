var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{ref:"main-header",staticClass:"header d-flex align-items-center justify-content-center",attrs:{"id":"home","dir":"rtl"}},[_c('span',{staticClass:"bottom-left-text",attrs:{"data-aos":"fade-right","data-aos-duration":"5000"}},[_vm._v(_vm._s(_vm.t("header").Threadfactory))]),_c('span',{staticClass:"bottom-right-text",attrs:{"data-aos":"fade-up","data-aos-duration":"5000"}},[_vm._v(_vm._s(_vm.t("header").alfaisalspinningcompany))]),_c('span',{staticClass:"top-left-text",attrs:{"data-aos":"fade-down","data-aos-duration":"5000"}})]),_c('mainSection'),_c('spinningStages',{attrs:{"title":_vm.stagesTitle.title}}),_c('mainSectionVideo'),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.services.length),expression:"services.length"}],staticClass:"services"},[_c('div',{staticClass:"services-container-title first"},[_c('h4',{staticClass:"title mb-0 mr-5"},[_vm._v(_vm._s(_vm.t("nav").services))]),_c('span',{staticClass:"title-border"}),_c('span',[_vm._v("A")]),_c('span',[_vm._v("L")]),_c('span',[_vm._v("F")]),_c('span',[_vm._v("A")]),_c('span',[_vm._v("I")]),_c('span',[_vm._v("S")]),_c('span',[_vm._v("A")]),_c('span',[_vm._v("L")])]),_c('div',{staticClass:"service-content py-5"},[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"py-5 py-lg-0"},[_c('img',{staticClass:"service-content-musk",attrs:{"src":"/images/website/service-musk-left.png"}}),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',_vm._l((_vm.services.slice(0, 3)),function(i,index){return _c('b-col',{key:index,attrs:{"cols":"12","sm":"4","lg":"12","data-aos":"fade-up","data-aos-duration":"5000"}},[_c('div',{staticClass:"service-content-card",class:{ active: index == _vm.activeServiceIndex - 1 },on:{"click":function($event){_vm.activeServiceIndex = index + 1}}},[_c('img',{attrs:{"src":_vm.services[index]
                            ? _vm.services[index].imageDto.path.replace(
                                '~',
                                _vm.$store.getters['app/domainHost']
                              )
                            : ''}})])])}),1)],1)],1)]),_c('b-col',{staticClass:"d-flex align-items-start justify-content-center flex-column",attrs:{"cols":"12","lg":"8"}},[_c('div',{staticClass:"service-text",attrs:{"data-aos":"fade-right","data-aos-duration":"5000"}},[_c('h3',{staticClass:"service-text-title"},[_vm._v(" "+_vm._s(_vm.services.length ? _vm.services[_vm.activeServiceIndex - 1].name : "")+" ")]),_c('div',{staticClass:"pr-5",domProps:{"innerHTML":_vm._s(
                  _vm.services.length
                    ? _vm.services[_vm.activeServiceIndex - 1].description
                    : ''
                )}}),_c('router-link',{attrs:{"to":"/services"}},[_c('b-button',{attrs:{"variant":"outline-primary"}},[_vm._v(_vm._s(_vm.t("global").more))])],1)],1)])],1)],1)],1),_vm._m(0)]),_c('section',{staticClass:"products"},[_c('div',{staticClass:"section-title-contaner"},[_c('h2',{staticClass:"section-title left"},[_vm._v(_vm._s(_vm.t("nav").products))])]),_c('div',{staticClass:"products-cards-container py-5"},[_c('img',{staticClass:"products-content-musk",attrs:{"src":"/images/website/products-musk-left.png"}}),_c('b-col',[_c('b-row',_vm._l((_vm.products),function(product,index){return _c('b-col',{key:index,staticClass:"products-card-parent",attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"products-card",attrs:{"data-aos":"fade-up","data-aos-duration":"5000"}},[_c('h4',{staticClass:"products-card-title"},[_vm._v(_vm._s(product.name))]),_c('div',{staticClass:"products-card-image-desc-container"},[_c('img',{staticClass:"products-card-image",attrs:{"src":product.imagePath.replace(
                      '~',
                      _vm.$store.getters['app/domainHost']
                    )}}),_c('div',{staticClass:"products-card-desc"},[(_vm.settings.showPrices)?_c('h5',{staticStyle:{"color":"#fdb844"}},[_vm._v(" "+_vm._s(product.price)+" ل.س ")]):_vm._e(),_c('div',{domProps:{"innerHTML":_vm._f("truncate")(product.description,80)}}),_c('router-link',{staticClass:"btn btn-outline-light",staticStyle:{"pointer-events":"auto"},attrs:{"to":'/products/' + product.id}},[_vm._v("عرض التفاصيل")])],1)])])])}),1)],1),_c('router-link',{attrs:{"to":"/products"}},[_c('b-button',{staticClass:"mx-auto",attrs:{"variant":"outline-primary"}},[_vm._v(_vm._s(_vm.t("global").more))])],1)],1)]),_c('section',{staticClass:"activities d-flex flex-column"},[_c('div',{staticClass:"section-title-contaner"},[_c('h2',{staticClass:"section-title right"},[_vm._v(" "+_vm._s(_vm.t("activites").latestactivities)+" ")])]),_c('mainSectionActivities',{staticClass:"flex-grow-1",attrs:{"isRun":_vm.isRunActiv}}),_c('div',[_c('router-link',{attrs:{"to":"/activities"}},[_c('b-button',{staticClass:"mx-auto",attrs:{"variant":"outline-primary"}},[_vm._v(_vm._s(_vm.t("global").more))])],1)],1)],1),(_vm.settings.showCustomers)?_c('section',{staticClass:"pt-3"},[_c('div',{staticClass:"section-title-contaner mb-3"},[_c('h2',{staticClass:"section-title left"},[_vm._v(_vm._s(_vm.t("customers").title))])]),_c('customerSection',{attrs:{"isRun":_vm.isRun}})],1):_vm._e(),_c('section',{staticClass:"agent pt-3"},[_c('div',{staticClass:"section-title-contaner mb-3"},[_c('h2',{staticClass:"section-title right"},[_vm._v(_vm._s(_vm.t("agent").ourexclusiveagent))])]),_c('mainSectionContact')],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"services-container-title last"},[_c('span',[_vm._v("G")]),_c('span',[_vm._v("N")]),_c('span',[_vm._v("I")]),_c('span',[_vm._v("N")]),_c('span',[_vm._v("N")]),_c('span',[_vm._v("I")]),_c('span',[_vm._v("P")]),_c('span',[_vm._v("S")]),_c('span',{staticClass:"title-border"})])}]

export { render, staticRenderFns }