<template>
  <div>
    <header
      id="home"
      class="header d-flex align-items-center justify-content-center"
      ref="main-header"
      dir="rtl"
    >
      <span
        class="bottom-left-text"
        data-aos="fade-right"
        data-aos-duration="5000"
        >{{ t("header").Threadfactory }}</span
      >
      <span
        class="bottom-right-text"
        data-aos="fade-up"
        data-aos-duration="5000"
        >{{ t("header").alfaisalspinningcompany }}</span
      >
      <span
        class="top-left-text"
        data-aos="fade-down"
        data-aos-duration="5000"
      ></span>
    </header>
    <mainSection />
    <spinningStages :title="stagesTitle.title" />
    <mainSectionVideo />
    <section class="services" v-show="services.length">
      <div class="services-container-title first">
        <h4 class="title mb-0 mr-5">{{ t("nav").services }}</h4>
        <span class="title-border"></span>
        <span>A</span>
        <span>L</span>
        <span>F</span>
        <span>A</span>
        <span>I</span>
        <span>S</span>
        <span>A</span>
        <span>L</span>
      </div>
      <div class="service-content py-5">
        <b-col>
          <b-row>
            <b-col cols="12" lg="4">
              <div class="py-5 py-lg-0">
                <img
                  class="service-content-musk"
                  src="/images/website/service-musk-left.png"
                />
                <b-col cols="12">
                  <b-row>
                    <b-col
                      cols="12"
                      sm="4"
                      lg="12"
                      v-for="(i, index) in services.slice(0, 3)"
                      :key="index"
                      data-aos="fade-up"
                      data-aos-duration="5000"
                    >
                      <div
                        class="service-content-card"
                        @click="activeServiceIndex = index + 1"
                        :class="{ active: index == activeServiceIndex - 1 }"
                      >
                        <img
                          :src="
                            services[index]
                              ? services[index].imageDto.path.replace(
                                  '~',
                                  $store.getters['app/domainHost']
                                )
                              : ''
                          "
                        />
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </div>
            </b-col>
            <b-col
              cols="12"
              lg="8"
              class="d-flex align-items-start justify-content-center flex-column"
            >
              <div
                class="service-text"
                data-aos="fade-right"
                data-aos-duration="5000"
              >
                <h3 class="service-text-title">
                  {{
                    services.length ? services[activeServiceIndex - 1].name : ""
                  }}
                </h3>
                <div
                  class="pr-5"
                  v-html="
                    services.length
                      ? services[activeServiceIndex - 1].description
                      : ''
                  "
                ></div>
                <router-link to="/services">
                  <b-button variant="outline-primary">{{
                    t("global").more
                  }}</b-button>
                </router-link>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </div>
      <div class="services-container-title last">
        <span>G</span>
        <span>N</span>
        <span>I</span>
        <span>N</span>
        <span>N</span>
        <span>I</span>
        <span>P</span>
        <span>S</span>
        <span class="title-border"></span>
      </div>
    </section>
    <section class="products">
      <div class="section-title-contaner">
        <h2 class="section-title left">{{ t("nav").products }}</h2>
      </div>
      <div class="products-cards-container py-5">
        <img
          src="/images/website/products-musk-left.png"
          class="products-content-musk"
        />
        <b-col>
          <b-row>
            <b-col
              cols="12"
              lg="4"
              class="products-card-parent"
              v-for="(product, index) in products"
              :key="index"
            >
              <div
                class="products-card"
                data-aos="fade-up"
                data-aos-duration="5000"
              >
                <h4 class="products-card-title">{{ product.name }}</h4>
                <div class="products-card-image-desc-container">
                  <img
                    class="products-card-image"
                    :src="
                      product.imagePath.replace(
                        '~',
                        $store.getters['app/domainHost']
                      )
                    "
                  />
                  <div class="products-card-desc">
                    <h5 style="color:#fdb844;" v-if="settings.showPrices">
                      {{ product.price }} ل.س
                    </h5>
                    <div :inner-html.prop="product.description | truncate(80)"></div>
                    <router-link
                      class="btn btn-outline-light"
                      style="pointer-events: auto"
                      :to="'/products/' + product.id"
                      >عرض التفاصيل</router-link
                    >
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <router-link to="/products">
          <b-button variant="outline-primary" class="mx-auto">{{
            t("global").more
          }}</b-button>
        </router-link>
      </div>
    </section>
    <section class="activities d-flex flex-column">
      <div class="section-title-contaner">
        <h2 class="section-title right">
          {{ t("activites").latestactivities }}
        </h2>
      </div>
      <mainSectionActivities :isRun="isRunActiv" class="flex-grow-1" />
      <div>
        <router-link to="/activities">
          <b-button variant="outline-primary" class="mx-auto">{{
            t("global").more
          }}</b-button>
        </router-link>
      </div>
    </section>
    <section class="pt-3" v-if="settings.showCustomers">
      <div class="section-title-contaner mb-3">
        <h2 class="section-title left">{{ t("customers").title }}</h2>
      </div>
      <customerSection :isRun="isRun" />
    </section>
    <section class="agent pt-3">
      <div class="section-title-contaner mb-3">
        <h2 class="section-title right">{{ t("agent").ourexclusiveagent }}</h2>
      </div>
      <mainSectionContact />
    </section>
  </div>
</template>
<script>
  import mainSection from "@/views/website/pages/about/components/main-section.vue";
  import spinningStages from "@/views/website/pages/services/components/spinning-stages";
  import mainSectionVideo from "@/views/website/pages/services/components/main-section.vue";
  import mainSectionContact from "@/views/website/pages/contact/components/main-section-contact.vue";
  import mainSectionActivities from "@/views/website/pages/activities/components/main-section-activities.vue";
  import customerSection from "@/views/website/pages/main/components/customer-section.vue";
  import { useUtils as useI18nUtils } from "@core/libs/i18n";
  import { handleLangChange } from "@/libs/event-bus";
  import { mapActions, mapGetters, mapState } from "vuex";

  export default {
    components: {
      mainSection,
      mainSectionVideo,
      spinningStages,
      mainSectionContact,
      mainSectionActivities,
      customerSection,
    },
    data: () => ({
      activeServiceIndex: 1,
      isRun: false,
      isRunActiv: false,
    }),
    metaInfo() {
      return {
        titleTemplate: "Al Faisal - " + this.$t("title.home"),
        htmlAttrs: {
          lang: this.$i18n.locale,
        },
        meta: [
          {
            name: "description",
            content: "description",
          },
          {
            name: "keywords",
            content: "keywords, keywords, keywords, ...",
          },
        ],
      };
    },
    computed: {
      ...mapState({
        settings: (state) => state.setting.settings,
      }),
      ...mapGetters(["services", "products","stagesTitle"]),
    },
    mounted() {
      this.init();
      handleLangChange(this.init);

      this.$store.commit("Set_Is_Nav_Active", true);
      addEventListener("scroll", this.getIsActive);
      addEventListener("resize", this.setServiceSectionAngle);
    },
    methods: {
      async init() {
        this.isRun = false;
        this.isRunActiv = false;
        this.setServiceSectionAngle();
        await this.getHomePageForWebsite();
        this.isRun = true;
        this.isRunActiv = true;
      },
      ...mapActions(["getHomePageForWebsite"]),
      getIsActive() {
        this.$store.commit(
          "Set_Is_Nav_Active",
          this.$refs["main-header"].clientHeight >= window.scrollY
        );
      },
      setServiceSectionAngle() {
        let servicesContainerTitleFirst = document.querySelector(
          ".services-container-title.first"
        );
        let servicesContainerTitleLast = document.querySelector(
          ".services-container-title.last"
        );
        let servicesContainerTitleTitle = document.querySelector(
          ".services-container-title .title"
        );
        let angle = (Math.atan(100 / innerWidth) * 180) / Math.PI;
        if (document.querySelector("html").dir == "rtl") {
          servicesContainerTitleFirst.style.transform =
            "rotate(" + -angle + "deg)";
          servicesContainerTitleLast.style.transform =
            "rotate(" + angle + "deg)";
          servicesContainerTitleTitle.style.transform =
            "rotate(" + angle + "deg)";
        } else {
          servicesContainerTitleFirst.style.transform =
            "rotate(" + angle + "deg)";
          servicesContainerTitleLast.style.transform =
            "rotate(" + -angle + "deg)";
          servicesContainerTitleTitle.style.transform =
            "rotate(" + -angle + "deg)";
        }
      },
    },
    beforeDestroy() {
      removeEventListener("scroll", this.getIsActive);
      this.$store.commit("Set_Is_Nav_Active", false);
      this.$store.dispatch("app/setLoadingWebsite", true);
    },
    setup() {
      const { t } = useI18nUtils();
      return { t };
    },
  };
</script>
<style lang="scss" scoped>
  @import "src/assets/scss/variables/variables.scss";
    .header {
        overflow: hidden;
        min-height: 500px;
        height: 100vh;
        background: linear-gradient( to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url("/images/website/fesal-lg.jpg") center center no-repeat;
        background-size: contain;
        background-color: #BDD503;
        position: relative;
        .top-left-text, .bottom-right-text, .bottom-left-text {
      position: absolute;
      padding: 8px 16px;
      color: $secondaryPrimary;
      font-size: 18px;
      &:after {
        position: absolute;
        content: "";
        background: $secondaryPrimary;
      }
    }
    .bottom-left-text {
      bottom: 30px;
      right: 40px;
      animation: slideLeft 1.2s linear;
      &:after {
        bottom: 0;
        right: -40px;
        height: 4px;
        width: 100%;
        animation: fillWidth 1.2s linear;
      }
    }
    .top-left-text {
      top: 0;
      right: 40px;
      height: 200px;
      &:after {
        top: 0;
        right: 0;
        height: 100%;
        width: 4px;
        animation: fillHeight 1.2s linear;
      }
    }
    .bottom-right-text {
      animation: slideDown 1.2s linear;
      writing-mode: tb;
      bottom: 100px;
      left: 40px;
      &:after {
        bottom: -100px;
        left: 0;
        height: 100%;
        width: 4px;
        animation: fillHeight 1.2s linear;
      }
    }
  }
  .services {
    position: relative;
    overflow: hidden;
    .services-container-title {
      display: flex;
      align-items: center;
      color: $primary;
      font-size: 12px;
      transform-origin: 0 center;
      .title {
        z-index: 1;
        background: $primary;
        padding: 12px 100px;
        color: #fff;
      }
      .title-border {
        flex-grow: 1;
        background: $primary;
        height: 3px;
      }
      span {
        padding: 0 14px;
      }
      &.first {
        position: relative;
        top: 12px;
        z-index: 1;
      }
    }
    .service-content {
      min-height: 350px;
      display: flex;
      position: relative;
      background: #efefef;
      clip-path: polygon(0 100px, 100% 0, 100% 100%, 0 calc(100% - 100px));
      .service-content-musk {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      .service-text {
        .service-text-title {
          padding: 1rem 3rem 1rem 0;
          font-weight: bold;
          border-bottom: solid 4px $secondaryPrimary;
          display: inline-block;
        }
        p {
          font-weight: bold;
          line-height: 2;
        }
      }
    }
  }
  .products-content-card,
  .service-content-card {
    height: 127px;
    width: 140px;
    margin: 40px auto;
    position: relative;
    transition: all 0.4s linear;
    &:after,
    &:before {
      content: "";
      width: 100%;
      height: 4px;
      background: $secondaryPrimary;
      position: absolute;
      transition: all 0.4s linear;
    }
    &:after {
      top: -6px;
      right: 0;
    }
    &:before {
      bottom: -6px;
      left: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .title {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      span {
        transition: all 0.4s linear;
        transform: scale(0);
      }
    }
    &.active,
    &:hover {
      transform: scale(1.4);
      &:after,
      &:before {
        width: 50%;
      }
      .title {
        span {
          transform: scale(1);
        }
      }
    }
  }
  .products {
    .products-cards-container {
      position: relative;
      .products-content-musk {
        position: absolute;
        left: 0;
        bottom: 0;
      }
      .products-card-parent {
        margin-bottom: 2rem;
        @media screen and (min-width: 992px) {
          &:nth-of-type(2) {
            margin-top: 40px;
          }
          &:last-of-type {
            margin-top: 80px;
          }
        }
        .products-card {
          padding: 10px 0;
          width: 300px;
          margin: auto;
          position: relative;
          &:after,
          &:before {
            content: "";
            position: absolute;
            left: 0;
            height: 3px;
            width: 100%;
            background: $primary;
          }
          &:after {
            top: 0;
          }
          &:before {
            bottom: 0;
          }
          .products-card-title {
            transition: all 0.4s linear;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            color: #fff;
          }
          .products-card-image-desc-container {
            position: relative;
            &:after {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.5);
              z-index: 1;
            }
            .products-card-image {
              height: 340px;
              width: 100%;
              z-index: 0;
            }
            .products-card-desc {
              pointer-events: none;
              transition: all 0.4s linear;
              position: absolute;
              left: -100%;
              bottom: 40px;
              z-index: -1;
              width: calc(100% + 14px);
              color: #fff;
              background: $primary;
              padding: 30px 1rem;
              padding-right: calc(1rem + 15px);
              text-align: center;
              line-height: 2;
              opacity: 0;
              visibility: hidden;
              &:after {
                content: "";
                position: absolute;
                bottom: -15px;
                width: 15px;
                height: 15px;
                right: 0;
                background: #621200;
                clip-path: polygon(0 0, 100% 0, 100% 100%);
              }
            }
          }
          &:hover {
            .products-card-title {
              top: 20%;
            }
            .products-card-image-desc-container {
              .products-card-desc {
                left: 0;
                z-index: 2;
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }
  .activities {
    min-height: 100vh;
    .activities-card {
      width: 260px;
      height: 260px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  [dir="ltr"] {
    .header {
      .home-arrow {
        transform: translate(81%, 93%);
      }
    }
    .services {
      .service-content {
        clip-path: polygon(0 0, 100% 100px, 100% calc(100% - 100px), 0 100%);
        .service-content-musk {
          transform: translateY(-50%) scaleX(-1);
        }
      }
    }
    .products {
      .products-cards-container {
        .products-content-musk {
          transform: scaleX(-1);
        }
        .products-card-parent {
          .products-card {
            .products-card-image-desc-container {
              .products-card-desc {
                &:after {
                  clip-path: polygon(0 0, 100% 0, 0 100%);
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .header {
        background: linear-gradient( to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url("/images/website/fesal-sm.jpg") center center no-repeat;
        background-size: contain !important;
        background-color: #BDD503;
    }
  }
  @keyframes dash {
    from {
      stroke-dashoffset: 2000;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
</style>
