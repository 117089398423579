<template>
  <div class="activites">
    <div dir="ltr" class="pt-2">
      <carousel
        :nav="false"
        :margin="10"
        rtl
        :responsive="{ 0: { items: 1 }, 600: { items: 7 } }"
        v-if="customerList.length && isRun"
      >
        <div
          class="activities-content-card"
          v-for="(customer, index) in customerList"
          :key="index"
           v-b-popover.hover.top="customer.name"
        >
          <img
            :src="
              customer.logoDto.path.replace(
                '~',
                $store.getters['app/domainHost']
              )
            "
          />
        </div>
      </carousel>
    </div>
  </div>
</template>

<script>
  import carousel from "vue-owl-carousel";
  import { mapGetters } from "vuex";
  export default {
    components: {
      carousel,
    },
    props: {
      id: String,
      isRun: Boolean
    },
    computed: {
      ...mapGetters(["customerList"]),
    }
  };
</script>
<style lang="scss" scoped>
  @import "src/assets/scss/variables/variables.scss";
  .activites {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .activities-content-card {
      height: 110;
      width: 120px;
      margin: 40px auto;
      position: relative;
      transition: all 0.4s linear;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .title {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0);
        transition: all 0.4s linear;
        color: #fff;
        span {
          transition: all 0.4s linear;
          transform: scale(0);
        }
      }
    }
  }
</style>
